import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import "../../css/custom-css/custom-products-services.css"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

// import bg from "../../../images/products-and-services/header-cmo.webp"
import bg from "../../images/services/banner-service.png"
import Seo from "../../components/seo"
import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

const LiquidCytology = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="Liquid Based Cytology" />
      <Header servicesPage />

      <BannerHeader
        background={bg}
        title="Liquid Based Cytology"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/services/service-liquid-1.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">Liquid Based Cytology</h4>
                    <p className="ml-4">
                      <ul>
                        <li>
                          The early detection program aims at finding and
                          treating early signs of cancer of the cervix. The
                          screening involves taking a Liquid Based Cytology
                          (LBC), which is a simple, quick vaginal examination to
                          check if the cervix is healthy. Some cells are gently
                          wiped off the cervix and sent to the laboratory for
                          testing.
                        </li>
                        <li>
                          The early detection of abnormal cells means further
                          investigations can be done and treatment can be given
                          if necessary, thereby preventing cancer from
                          developing.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer hideContactInfo />
    </div>
  )
}

export default LiquidCytology
